<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <b-row>
                  <b-col><h6 class="mb-0">Upload File đối soát</h6></b-col>
                  <b-col class="text-right">
                    <a href="/#/reconcile/reconciles" class="btn btn-success">
                      <i class="fa fa-list"></i>
                      Danh sách
                    </a>
                  </b-col>
                </b-row>
              </template>
              <div>
                <b-form @submit="onSubmit">
                  <b-row>
                  <b-form-group id="input-group-4" class="col-6">
                    <label>Vui lòng chọn loại (*)</label>
                    <b-form-select v-model="type">
                      <option value="1">Ngân hàng liên kết</option>
                      <option value="2">ĐVCƯDV</option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group id="input-group-4" class="col-6" v-if="type == 1" >
                    <label>Vui lòng chọn bank(*)</label>
                    <b-form-select v-model="prov">
                      <b-form-select-option v-for="item in provider_bank" v-bind:key="item.value" :value="item.value">{{item.name}}</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                    <b-form-group id="input-group-4" class="col-6" v-if="type == 2" >
                      <label>Vui lòng chọn ĐVCƯDV(*)</label>
                      <b-form-select v-model="prov">
                        <b-form-select-option v-for="item in provider_other" v-bind:key="item.value" :value="item.value">{{item.name}}</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-row>
                  <b-form-group id="input-group-4">
                    <label>Vui lòng chọn file đối soát</label>
                    <b-form-file
                      v-model="file"
                      :state="Boolean(file)"
                      placeholder="Choose a file or drop it here...(xls, xlsx)"
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                  </b-form-group>
                  <b-button type="button" variant="primary" @click="onSubmit">Submit</b-button>
                </b-form>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import CmsRepository from "@/core/repositories/cmsRepository";

export default {
  name: "Create",
  mixins: [Common],
  components: {},
  data() {
    return {
      file: null,
      type: 1,
      prov: 'TCB',
      extensions: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ],
      provider_bank: [
        {"name": "Techcombank", "value": "TCB"},
        {"name": "Sacombank", "value": "SACOMBANK"},
        {"name": "Vietinbank", "value": "CTG"},
        {"name": "VPBank", "value": "VPB"},
        {"name": "Bản Việt", "value": "VCCB"},
        {"name": "MSB", "value": "MSB"},
        {"name": "Vietcombank", "value": "VCB"},
      ],
      provider_other: [
        {"name": "Payoo", "value": "PAYOO"},
        {"name": "Imedia", "value": "IMEDIA"},
        {"name": "Imedia (thẻ)", "value": "IMEDIA_CARD"},
        {"name": "Epay", "value": "EPAY"},
        {"name": "Epay (thẻ)", "value": "EPAY_CARD"},
      ],
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  created() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Upload File Đối soát" },]);
  },
  methods: {
    onSubmit() {
      if (!this.file) {
        alert("Vui lòng chọn file");
        return false;
      }

      if (this.extensions.indexOf(this.file.type) === -1) {
        alert("File không đúng định dạng, vui lòng kiểm tra lại");
        return false;
      }

      if (this.file.size <= 0) {
        alert("File rỗng, vui lòng kiểm tra lại");
        return false;
      }

      let param = new FormData();
      param.append("file", this.file);
      param.append("provider", this.prov);

      CmsRepository.addFileReconcile(param).then(function(response) {
          if (response.data.error_code) {
            alert(response.data.message);
            return;
          }
          alert("Đẩy file thành công");
          return;
        })
        .catch(function () {
          alert("Có lỗi xảy ra");
      });
    },
  },
};
</script>
